/*!

Copyright 2016-present Palantir Technologies, Inc. All rights reserved.
Licensed under the Apache License, Version 2.0.

*/
@-webkit-keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.bp3-table-cell {
  display: inline-block;
  height: 20px;
  padding: 0 10px;
  line-height: 20px;
  font-size: 12px;
  -webkit-transition: color 300ms;
  transition: color 300ms; }

.bp3-dark .bp3-table-cell:not([class*="bp3-intent-"]):not(.bp3-loading) {
  color: #f5f8fa; }

.bp3-table-cell.bp3-intent-primary {
  background-color: rgba(19, 124, 189, 0.1);
  color: #137cbd; }

.bp3-dark .bp3-table-cell.bp3-intent-primary {
  background: rgba(19, 124, 189, 0.1);
  color: #2b95d6; }

.bp3-table-cell.bp3-intent-success {
  background-color: rgba(15, 153, 96, 0.1);
  color: #0f9960; }

.bp3-dark .bp3-table-cell.bp3-intent-success {
  background: rgba(15, 153, 96, 0.1);
  color: #15b371; }

.bp3-table-cell.bp3-intent-warning {
  background-color: rgba(217, 130, 43, 0.1);
  color: #d9822b; }

.bp3-dark .bp3-table-cell.bp3-intent-warning {
  background: rgba(217, 130, 43, 0.1);
  color: #f29d49; }

.bp3-table-cell.bp3-intent-danger {
  background-color: rgba(219, 55, 55, 0.1);
  color: #db3737; }

.bp3-dark .bp3-table-cell.bp3-intent-danger {
  background: rgba(219, 55, 55, 0.1);
  color: #f55656; }

.bp3-table-editing-enabled .bp3-table-cell {
  cursor: text; }

.bp3-table-selection-enabled .bp3-table-cell {
  cursor: cell; }

.bp3-table-cell.bp3-table-truncated-cell {
  overflow: hidden; }

.bp3-table-cell.bp3-large,
.bp3-large .bp3-table-cell {
  height: 30px;
  line-height: 30px;
  font-size: 14px; }

.bp3-table-cell.bp3-loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: transparent; }

.bp3-table-cell:focus {
  outline: none; }

.bp3-table-cell-interactive {
  z-index: 21; }

.bp3-table-striped .bp3-table-cell-ledger-even {
  background-color: #ffffff; }

.bp3-table-striped .bp3-table-cell-ledger-odd {
  background-color: #fafcfd; }

.bp3-dark .bp3-table-striped .bp3-table-cell-ledger-even {
  background-color: #293742; }

.bp3-dark .bp3-table-striped .bp3-table-cell-ledger-odd {
  background-color: #2d3c48; }

.bp3-table-editable-name input {
  height: 20px; }

.bp3-table-editable-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 10px; }

.bp3-table-null {
  color: rgba(92, 112, 128, 0.5); }

.bp3-table-truncated-value {
  position: absolute;
  top: 0;
  right: 35px;
  left: 10px;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp3-table-truncated-format-text {
  position: absolute;
  top: 0;
  right: 10px;
  left: 10px;
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp3-table-truncated-popover-target {
  position: absolute;
  top: 0;
  right: 5px;
  bottom: 0;
  opacity: 0.3;
  border-radius: 3px;
  cursor: pointer;
  padding: 0 5px;
  text-align: center; }

.bp3-table-truncated-popover-target .bp3-icon-standard {
  line-height: 20px; }

.bp3-table-truncated-popover-target.bp3-popover-open {
  opacity: 1; }

.bp3-table-truncated-popover-target.bp3-popover-open .bp3-icon-standard {
  color: #137cbd; }

.bp3-table-truncated-popover-target:hover {
  opacity: 1; }

.bp3-table-truncated-popover {
  min-width: 200px;
  max-width: 600px;
  max-height: 300px;
  overflow: auto;
  padding: 10px 10px;
  font-family: monospace; }

.bp3-table-popover-whitespace-pre {
  white-space: pre; }

.bp3-table-popover-whitespace-normal {
  white-space: normal; }

.bp3-table-container {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.15); }

.bp3-table-menu {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15); }

.bp3-table-cell {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-horizontal-cell-divider {
  -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.15);
  height: 1px; }

.bp3-table-column-headers .bp3-table-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-column-headers .bp3-table-header::before {
  right: 1px;
  bottom: 0; }

.bp3-table-row-headers .bp3-table-header {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-row-headers .bp3-table-header::before {
  right: 0;
  bottom: 1px; }

.bp3-table-body .bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-body .bp3-table-last-in-column {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-body .bp3-table-last-in-row.bp3-table-last-in-column {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row::before {
  right: 3px;
  bottom: 0; }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), 1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column::before {
  right: 0;
  bottom: 3px; }

.bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.15), inset -3px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-table-quadrant-top .bp3-table-cell.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.15), inset -1px 0 0 rgba(16, 22, 26, 0.15); }

.bp3-dark .bp3-table-container {
  -webkit-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-menu {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-cell {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-horizontal-cell-divider {
  -webkit-box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 1px 0 rgba(16, 22, 26, 0.4);
  height: 1px; }

.bp3-dark .bp3-table-column-headers .bp3-table-header {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-column-headers .bp3-table-header::before {
  right: 1px;
  bottom: 0; }

.bp3-dark .bp3-table-row-headers .bp3-table-header {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-row-headers .bp3-table-header::before {
  right: 0;
  bottom: 1px; }

.bp3-dark .bp3-table-body .bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-body .bp3-table-last-in-column {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-body .bp3-table-last-in-row.bp3-table-last-in-column {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-cell.bp3-table-last-in-column.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row {
  -webkit-box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: 0 1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-row::before {
  right: 3px;
  bottom: 0; }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), 1px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top-left .bp3-table-header.bp3-table-last-in-column::before {
  right: 0;
  bottom: 3px; }

.bp3-dark .bp3-table-quadrant-left .bp3-table-cell.bp3-table-last-in-row {
  -webkit-box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -1px 0 rgba(16, 22, 26, 0.4), inset -3px 0 0 rgba(16, 22, 26, 0.4); }

.bp3-dark .bp3-table-quadrant-top .bp3-table-cell.bp3-table-last-in-column {
  -webkit-box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 -3px 0 rgba(16, 22, 26, 0.4), inset -1px 0 0 rgba(16, 22, 26, 0.4); }

@keyframes skeleton-fade-in {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.bp3-table-header {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  text-overflow: ellipsis;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-table-header::before {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  content: ""; }

.bp3-table-header .bp3-table-row-name,
.bp3-table-header .bp3-table-column-name,
.bp3-table-header .bp3-table-header-content {
  position: relative;
  width: 100%; }

.bp3-table-selection-enabled .bp3-table-header:hover::before, .bp3-table-header.bp3-table-header-active::before {
  background-color: #e1e8ed; }

.bp3-dark .bp3-table-selection-enabled .bp3-table-header:hover::before, .bp3-dark .bp3-table-header.bp3-table-header-active::before {
  background-color: #394b59; }

.bp3-table-header.bp3-table-header-selected::before {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(19, 124, 189, 0.1)), to(rgba(19, 124, 189, 0.1)));
  background-image: linear-gradient(90deg, rgba(19, 124, 189, 0.1), rgba(19, 124, 189, 0.1)); }

.bp3-table-th-menu-container {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: absolute;
  right: 1px;
  opacity: 0;
  text-align: right; }

.bp3-table-header:hover .bp3-table-th-menu-container,
.bp3-table-header-active .bp3-table-th-menu-container, .bp3-table-th-menu-container.bp3-table-th-menu-open {
  opacity: 1; }

.bp3-table-interaction-bar .bp3-table-th-menu-container {
  line-height: 20px; }

.bp3-table-th-menu-container-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 30px;
  pointer-events: none; }

.bp3-table-interaction-bar .bp3-table-th-menu-container-background {
  height: 20px; }

.bp3-table-th-menu-open .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 248, 250, 0)), color-stop(50%, #f5f8fa));
  background-image: linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }

.bp3-dark .bp3-table-th-menu-open .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(48, 64, 77, 0)), color-stop(50%, #30404d));
  background-image: linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }

.bp3-table-header:hover .bp3-table-th-menu-container-background,
.bp3-table-header-active .bp3-table-th-menu-container-background,
.bp3-table-th-menu-open .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(245, 248, 250, 0)), color-stop(50%, #f5f8fa));
  background-image: linear-gradient(90deg, rgba(245, 248, 250, 0) 0%, #f5f8fa 50%); }

.bp3-dark .bp3-table-header:hover .bp3-table-th-menu-container-background, .bp3-dark
.bp3-table-header-active .bp3-table-th-menu-container-background, .bp3-dark
.bp3-table-th-menu-open .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(48, 64, 77, 0)), color-stop(50%, #30404d));
  background-image: linear-gradient(90deg, rgba(48, 64, 77, 0) 0%, #30404d 50%); }

.bp3-table-selection-enabled .bp3-table-header:hover .bp3-table-th-menu-container-background,
.bp3-table-selection-enabled .bp3-table-header-active .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(225, 232, 237, 0)), color-stop(50%, #e1e8ed));
  background-image: linear-gradient(90deg, rgba(225, 232, 237, 0) 0%, #e1e8ed 50%); }

.bp3-dark .bp3-table-selection-enabled .bp3-table-header:hover .bp3-table-th-menu-container-background, .bp3-dark
.bp3-table-selection-enabled .bp3-table-header-active .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(57, 75, 89, 0)), color-stop(50%, #394b59));
  background-image: linear-gradient(90deg, rgba(57, 75, 89, 0) 0%, #394b59 50%); }

.bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(222, 236, 244, 0)), color-stop(50%, #deecf4));
  background-image: linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }

.bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(45, 70, 88, 0)), color-stop(50%, #2d4658));
  background-image: linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }

.bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected:hover .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(204, 221, 232, 0)), color-stop(50%, #ccdde8));
  background-image: linear-gradient(90deg, rgba(204, 221, 232, 0) 0%, #ccdde8 50%); }

.bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected:hover .bp3-table-th-menu-container-background {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(53, 80, 99, 0)), color-stop(50%, #355063));
  background-image: linear-gradient(90deg, rgba(53, 80, 99, 0) 0%, #355063 50%); }

.bp3-table-th-menu {
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px; }

.bp3-table-interaction-bar .bp3-table-th-menu {
  right: 1px;
  width: 20px;
  height: 20px;
  text-align: center; }

.bp3-table-interaction-bar .bp3-table-th-menu .bp3-icon {
  margin: 2px;
  margin-left: 3px;
  vertical-align: top; }

.bp3-table-th-menu .bp3-icon {
  margin-top: 7px;
  margin-right: 7px;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.2);
  background-color: #f5f8fa;
  color: #5c7080; }

.bp3-table-selection-enabled .bp3-table-th-menu .bp3-icon {
  background-color: #e1e8ed; }

.bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu .bp3-icon {
  background-color: linear-gradient(90deg, rgba(222, 236, 244, 0) 0%, #deecf4 50%); }

.bp3-dark .bp3-table-th-menu .bp3-icon {
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.2);
  background-color: #30404d;
  color: #bfccd6; }

.bp3-dark .bp3-table-selection-enabled .bp3-table-th-menu .bp3-icon {
  background-color: #394b59; }

.bp3-dark .bp3-table-selection-enabled .bp3-table-header.bp3-table-header-selected .bp3-table-th-menu .bp3-icon {
  background-color: linear-gradient(90deg, rgba(45, 70, 88, 0) 0%, #2d4658 50%); }

.bp3-table-th-menu:hover .bp3-icon {
  -webkit-box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4);
  color: #182026; }

.bp3-dark .bp3-table-th-menu:hover .bp3-icon {
  -webkit-box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.4);
  color: #f5f8fa; }

.bp3-table-th-menu.bp3-popover-open .bp3-icon {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #137cbd;
  color: #ffffff; }

.bp3-dark .bp3-table-th-menu.bp3-popover-open .bp3-icon {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #137cbd;
  color: #ffffff; }

.bp3-table-thead {
  display: block;
  white-space: nowrap; }

.bp3-table-column-header-tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.bp3-table-column-header-tr .bp3-table-header {
  -webkit-box-flex: 0;
  -ms-flex: 0 0;
  flex: 0 0; }

.bp3-table-column-headers .bp3-table-interaction-bar {
  position: relative;
  height: 20px; }

.bp3-table-column-headers .bp3-table-header {
  min-height: 30px;
  vertical-align: top;
  line-height: 30px; }

.bp3-table-row-headers .bp3-table-header {
  min-width: 30px;
  overflow: hidden;
  line-height: 20px; }

.bp3-table-column-name-text,
.bp3-table-row-name-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  pointer-events: none; }

.bp3-table-truncated-text {
  max-height: 100%;
  overflow: hidden;
  text-overflow: ellipsis; }

.bp3-table-no-wrap-text {
  white-space: nowrap; }

.bp3-table-column-name-text {
  padding: 0 10px; }

.bp3-table-editable-name {
  display: block;
  pointer-events: all; }

.bp3-table-editable-name.bp3-editable-text::before {
  top: -1px;
  right: -10px;
  bottom: 0;
  left: -11px;
  border-radius: 0; }

.bp3-table-editable-name.bp3-editable-text:not(.bp3-editable-editing)::before {
  -webkit-box-shadow: none;
  box-shadow: none; }

.bp3-table-editable-name.bp3-editable-text.bp3-editable-editing::before {
  top: 0px;
  right: -9px;
  bottom: 1px;
  left: -10px;
  cursor: text; }

.bp3-table-editable-name.bp3-editable-text.bp3-editable-editing.bp3-table-editable-text::before {
  right: 1px;
  left: 0px; }

.bp3-table-column-name .bp3-table-editable-name.bp3-editable-text::before {
  bottom: -1px; }

.bp3-table-column-name .bp3-table-editable-name.bp3-editable-text.bp3-editable-editing::before {
  bottom: 0; }

.bp3-table-column-name-text .bp3-table-editable-name input {
  height: 30px; }

.bp3-table-column-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: left;
  font-size: 14px; }

.bp3-table-row-name {
  display: block;
  padding: 0 5px;
  text-align: right;
  font-size: 12px; }

.bp3-table-header-content {
  white-space: normal;
  font-size: 14px; }

.bp3-table-header-content .is-searchable:not(.is-focused) > .Select-control {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none; }

.bp3-table-header-content .is-searchable > .Select-control {
  border-radius: 0; }

.bp3-table-header-content .is-searchable > .Select-control .Select-value {
  cursor: pointer; }

.bp3-table-header-content .Select-value {
  right: -1px; }

.bp3-table-column-name,
.bp3-table-row-name {
  -webkit-transition: color 300ms;
  transition: color 300ms; }

.bp3-table-header.bp3-loading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.bp3-table-header.bp3-loading .bp3-table-column-name,
.bp3-table-header.bp3-loading .bp3-table-row-name {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.bp3-table-header.bp3-loading .bp3-table-column-name-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px; }

.bp3-table-header.bp3-loading .bp3-table-column-name-text .bp3-skeleton {
  height: 8px; }

.bp3-table-header.bp3-loading .bp3-table-row-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }

.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header {
  cursor: s-resize; }

.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable {
  cursor: -webkit-grab;
  cursor: grab; }

.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar {
  cursor: -webkit-grab;
  cursor: grab; }

.bp3-table-selection-enabled.bp3-table-column-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header {
  cursor: e-resize; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable {
  cursor: -webkit-grab;
  cursor: grab; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar {
  cursor: -webkit-grab;
  cursor: grab; }

.bp3-table-selection-enabled.bp3-table-row-headers .bp3-table-header.bp3-table-header-reorderable .bp3-table-interaction-bar:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-selection-enabled.bp3-table-menu {
  cursor: se-resize; }

.bp3-table-selection-enabled .bp3-editable-text::before,
.bp3-table-selection-enabled .bp3-editable-content {
  cursor: cell; }

.bp3-table-column-header-cell.bp3-table-has-reorder-handle:not(.bp3-table-has-interaction-bar) .bp3-table-column-name-text {
  padding-left: 22px; }

.bp3-table-column-header-cell.bp3-table-has-reorder-handle:not(.bp3-table-has-interaction-bar) .bp3-table-editable-name::before {
  left: -22px; }

.bp3-table-reorder-handle-target {
  cursor: -webkit-grab;
  cursor: grab;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22px;
  color: rgba(92, 112, 128, 0.5); }

.bp3-table-reorder-handle-target:active {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-reorder-handle-target:hover {
  color: #182026; }

.bp3-table-reorder-handle-target:active {
  color: #137cbd; }

.bp3-dark .bp3-table-reorder-handle-target {
  color: rgba(191, 204, 214, 0.5); }

.bp3-dark .bp3-table-reorder-handle-target:hover {
  color: #f5f8fa; }

.bp3-dark .bp3-table-reorder-handle-target:active {
  color: #137cbd; }

.bp3-table-reorder-handle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.bp3-table-resize-handle-target {
  position: absolute;
  opacity: 0;
  z-index: 20;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-table-resize-handle-target:hover, .bp3-table-resize-handle-target.bp3-table-dragging {
  opacity: 1; }

.bp3-table-resize-handle-target.bp3-table-resize-vertical {
  top: 0;
  right: 0;
  bottom: -1px;
  cursor: ew-resize;
  width: 5px; }

.bp3-table-resize-handle-target.bp3-table-resize-horizontal {
  right: -1px;
  bottom: 0;
  left: 0;
  cursor: ns-resize;
  height: 5px; }

.bp3-table-resize-handle {
  position: absolute;
  z-index: 20;
  background-color: #137cbd; }

.bp3-table-resize-handle.bp3-table-dragging {
  background-color: #137cbd; }

.bp3-table-resize-vertical .bp3-table-resize-handle {
  top: 0;
  bottom: 0;
  left: 2px;
  width: 3px; }

.bp3-table-resize-horizontal .bp3-table-resize-handle {
  top: 2px;
  right: 0;
  left: 0;
  height: 3px; }

.bp3-table-resize-guides .bp3-table-horizontal-guide {
  margin-top: -3px;
  background-color: #137cbd;
  height: 3px; }

.bp3-table-resize-guides .bp3-table-horizontal-guide.bp3-table-horizontal-guide-flush-top {
  margin-top: 0; }

.bp3-table-resize-guides .bp3-table-vertical-guide {
  margin-left: -3px;
  background-color: #137cbd;
  width: 3px; }

.bp3-table-resize-guides .bp3-table-vertical-guide.bp3-table-vertical-guide-flush-left {
  margin-left: 0; }

.bp3-table-overlay-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  overflow: hidden;
  pointer-events: none; }

.bp3-table-overlay {
  position: absolute; }

.bp3-table-region {
  border: 1px solid #8a9ba8;
  background-color: rgba(138, 155, 168, 0.1); }

.bp3-table-selection-region {
  border: 1px solid #137cbd;
  background-color: rgba(19, 124, 189, 0.1); }

.bp3-table-column-headers .bp3-table-selection-region {
  background-color: transparent; }

.bp3-table-row-headers .bp3-table-selection-region {
  background-color: transparent; }

.bp3-table-focus-region {
  border: 2px solid #137cbd; }

.bp3-table-column-headers .bp3-table-region {
  border-bottom: none; }

.bp3-table-row-headers .bp3-table-region {
  border-right: none; }

.bp3-table-vertical-guide {
  top: 0;
  bottom: 0; }

.bp3-table-horizontal-guide {
  right: 0;
  left: 0; }

.bp3-table-reordering-cursor-overlay {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.bp3-table-reordering .bp3-table-reordering-cursor-overlay {
  pointer-events: all; }

.bp3-table-quadrant-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  height: 100%; }

.bp3-table-quadrant {
  position: absolute;
  top: 0;
  left: 0;
  background: #f5f8fa;
  overflow: hidden; }

.bp3-dark .bp3-table-quadrant {
  background-color: #30404d; }

.bp3-table-quadrant-scroll-container {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bp3-table-no-vertical-scroll .bp3-table-quadrant-scroll-container {
  overflow-y: hidden; }

.bp3-table-no-horizontal-scroll .bp3-table-quadrant-scroll-container {
  overflow-x: hidden; }

.bp3-table-quadrant-body-container {
  position: relative; }

.bp3-table-quadrant-main {
  position: relative;
  top: auto;
  left: auto;
  z-index: 0;
  width: 100%;
  height: 100%; }

.bp3-table-quadrant-main .bp3-table-quadrant-scroll-container {
  width: 100%;
  height: 100%; }

.bp3-table-quadrant-main .bp3-table-cell-client {
  background: #ffffff; }

.bp3-table-quadrant-top {
  right: 0;
  z-index: 1; }

.bp3-table-quadrant-top .bp3-table-quadrant-scroll-container {
  bottom: -20px;
  overflow-y: hidden; }

.bp3-table-quadrant-left {
  bottom: 0;
  z-index: 2;
  -webkit-transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-table-quadrant-left .bp3-table-quadrant-scroll-container {
  position: absolute;
  top: 0;
  right: -20px;
  bottom: 0;
  height: auto;
  overflow-x: hidden; }

.bp3-table-quadrant-left .bp3-table-body-virtual-client {
  min-width: 1px; }

.bp3-table-quadrant-top-left {
  z-index: 3;
  -webkit-transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-table-quadrant-top-left .bp3-table-quadrant-scroll-container {
  right: -20px;
  bottom: -20px;
  overflow-x: hidden;
  overflow-y: hidden; }

.bp3-table-quadrant-top-left .bp3-table-body-virtual-client {
  min-width: 1px; }

.bp3-table-container {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #f5f8fa;
  max-width: 100%;
  height: 100%;
  min-height: 60px;
  max-height: 100%;
  overflow: hidden; }

.bp3-dark .bp3-table-container {
  background-color: #30404d; }

.bp3-table-container .bp3-loading {
  color: transparent; }

.bp3-table-container .bp3-loading .bp3-skeleton {
  opacity: 0;
  height: 5px;
  -webkit-animation: 300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
  animation: 300ms linear forwards skeleton-fade-in, 1000ms linear infinite alternate skeleton-glow;
  -webkit-animation-delay: 0s, 300ms;
  animation-delay: 0s, 300ms; }

.bp3-table-top-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-height: 0%; }

.bp3-table-container.bp3-table-no-rows .bp3-table-top-container {
  padding-bottom: 1px; }

.bp3-table-bottom-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  height: 100%;
  min-height: 0%;
  color: #182026; }

.bp3-dark .bp3-table-bottom-container {
  color: #f5f8fa; }

.bp3-table-menu {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  z-index: 13;
  background-color: #f5f8fa; }

.bp3-dark .bp3-table-menu {
  background-color: #30404d; }

.bp3-table-column-headers {
  display: block;
  position: relative;
  z-index: 11;
  background-color: #f5f8fa;
  color: #182026; }

.bp3-dark .bp3-table-column-headers {
  background-color: #30404d;
  color: #f5f8fa; }

.bp3-table-row-headers {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  z-index: 12;
  background-color: #f5f8fa;
  color: #5c7080;
  -webkit-transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  transition: width 100ms cubic-bezier(0.4, 1, 0.75, 0.9); }

.bp3-dark .bp3-table-row-headers {
  background-color: #30404d;
  color: #bfccd6; }

.bp3-table-body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  position: relative;
  z-index: 10;
  overflow: scroll; }

.bp3-table-body-virtual-client {
  position: relative; }

.bp3-table-cell-client {
  background: #ffffff; }

.bp3-dark .bp3-table-cell-client {
  background: #293742; }

.bp3-table-tbody {
  display: block;
  white-space: nowrap; }

.bp3-table-cell {
  display: inline-block; }

.bp3-table-no-layout {
  display: inline-block;
  position: absolute; }

.bp3-table-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 100px;
  height: 100px;
  overflow: scroll; }

/*# sourceMappingURL=table.css.map */
